// extracted by mini-css-extract-plugin
export var alt = "NotFound-module--alt--b8b05";
export var backButton = "NotFound-module--backButton--b9732";
export var backLink = "NotFound-module--back-link--d5687";
export var container = "NotFound-module--container--9e061";
export var desktopOnly = "NotFound-module--desktopOnly--70ff4";
export var logo = "NotFound-module--logo--bda83";
export var main = "NotFound-module--main--2fe0a";
export var message = "NotFound-module--message--e558b";
export var messageContainer = "NotFound-module--messageContainer--34fda";
export var mobileOnly = "NotFound-module--mobileOnly--b649d";
export var styledContainer = "NotFound-module--styledContainer--ca1f4";
export var textLeft = "NotFound-module--textLeft--845a2";
export var textWhite = "NotFound-module--text-white--87533";
export var visuallyHidden = "NotFound-module--visuallyHidden--39850";