import * as React from "react";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import {
  StyledButton,
  StyledContainer,
  StyledRow,
} from "../pageHelpers/NotFound/NotFoundHelpers";

const NotFoundPage: React.FC = () => {
  return (
    <Layout hideSocialBar={true}>
      <SEO title="404: Page Not Found" />
      <StyledContainer>
        <StyledRow>
          <div className="title">Destination Unknown.</div>
          <div className="subTitle">
            Sorry, we can&apos;t find the page you are looking for.
          </div>
          <StyledButton>Back To Homepage</StyledButton>
        </StyledRow>
      </StyledContainer>
    </Layout>
  );
};

export default NotFoundPage;
